import React from 'react';
import { signin } from './api';

class LoginPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: ""
		};

	    this.handleChangeUsername = this.handleChangeUsername.bind(this);
	    this.handleChangePassword = this.handleChangePassword.bind(this);
	}

  handleChangeUsername(event) {
    this.setState({username: event.target.value});
  }

  handleChangePassword(event) {
    this.setState({password: event.target.value});
  }

  submit = (e) => {
    e.preventDefault();
	  signin(this.state.username, this.state.password).then( result => { if (result) { window.location = result.userData ? "#bookings" : "#bookings"; }; setTimeout(window.prepareDocument,500); });
  }

  render() {
    return (
              <div class="page">
            <div class="page-content h-100">
                <div class="background theme-header"><img src="img/city2.jpg" alt="" /></div>
                <div class="row mx-0 h-100 justify-content-center">
                    <div class="col-10 col-md-6 col-lg-4 my-3 mx-auto text-center align-self-center">
                        <img src="img/logo-w.png" alt="" class="login-logo" />
                        <h1 class="login-title"><small>Welcome to,</small><br />Makerspace Delft</h1>
                        <br />
                        <h5 class="text-white mb-4">Sign in</h5>
    <form onSubmit={this.submit} name="login">
                        <div class="login-input-content ">
                            <div class="form-group">
                                <input type="text" value={this.state.username} onChange={this.handleChangeUsername} class="form-control rounded text-center" placeholder="Username" />
                            </div>
                            <div class="form-group">
                                <input type="password" value={this.state.password} onChange={this.handleChangePassword} class="form-control rounded text-center" placeholder="Password" />
                            </div>
                            <input type="submit" class="btn btn-block btn-success rounded border-0 z-3" value="Sign in" />
                        </div>
                        </form>
                        <br />
                        <br />
                        <div class="row no-gutters">
                            <div class="col-6 text-left"><a href="#" class="text-white mt-3" style={{display:"none"}}>Forget Password?</a></div>
                            <div class="col-6 text-right"><a href="#register" class="text-white text-center mt-3">Sign up</a></div>
                        </div>                        
                    </div>
                </div>

                <br />

            </div>

        </div>
    )
  }
}

export { LoginPage }
