import './App.css';

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

import { Loader, SearchBar, Header, Footer, Sidebar, ThemeController, Page, AppWrapper } from './Overux'

import { BookingsPage, BookingModal } from './BookingsPage'
import { AccountPage } from './AccountPage'
import { ContactPage } from './ContactPage'
import { LoginPage } from './LoginPage'
import { RegisterPage } from './RegisterPage'

import { accountInfo } from './api'

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			firstname: "",
			lastname: ""
		};
		accountInfo().then(this.recieveAccountInfo);
	}

	recieveAccountInfo = (data) => {
		if (data["msg"]) {
		var info = data["msg"];
		this.setState({
			firstname: info["firstname"],
			lastname: info["lastname"]
		});
		}
	}

  render() {
   return (
     <AppWrapper>
     <Switch>
     <Route path="/login" component={LoginPage} />
     <Route path="/register" component={RegisterPage} />
     <Route>
     <div>
     <Sidebar firstname={this.state.firstname} />
       <Page>
         <Switch>
           <Route exact path="/">
             <Redirect to="/bookings" />
           </Route>
           <Route path='/bookings' component={BookingsPage} />
           <Route path='/account' component={AccountPage} />
           <Route path='/contact' component={ContactPage} />
         </Switch>
       </Page>
       <BookingModal />
       <ThemeController />
       </div>
       </Route>
       </Switch>
     </AppWrapper>
   ) 
  }
}

export default App;
