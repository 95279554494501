import React from 'react';

import { getAvailability, formatDate, dateString, bookings, cancelBooking, registerBooking, accountInfo} from './api'

class BookingModal extends React.Component {

	recieveData = (data) => {
		if (data) {
		var bookingsData = data["msg"];
		this.setState({
			availableBookings: bookingsData,
			selectedStart: Object.keys(bookingsData[this.state.selectedDay])[0],
			selectedEnd: Object.keys(bookingsData[this.state.selectedDay])[1]
		});
		this.updateMenus();
		}
	}

	reload = () => {
		getAvailability(dateString(0),dateString(14)).then(this.recieveData);
	}

	constructor(props) {
		super(props);
		this.state = {
			availableBookings: null,
			selectedDayBookings: [],
			disabledStartTimes: [],
			npeople: 1,
			selectedDay: 0,
			selectedStart: null,
			selectedEnd: null,
		};
		this.reload();
	}

	submit = (e) => {
		e.preventDefault();
		registerBooking(dateString(parseInt(this.state.selectedDay)), this.state.selectedStart, this.state.selectedEnd, this.state.npeople).then( () => { window.location.reload(); } );
		window.$('#hireme').modal('hide');
		//setTimeout(() => { this.reload(); }, 1000);
		// window.location.reload();
	}

	updateMenus = (state = {}) => {

		var selectedStart = Math.max("selectedStart" in state ? state["selectedStart"] : this.state.selectedStart, 0)
		var selectedEnd = "selectedEnd" in state ? state["selectedEnd"] : this.state.selectedEnd;
		var npeople = "npeople" in state ? state["npeople"] : this.state.npeople;
		var selectedDay = "selectedDay" in state ? state["selectedDay"] : this.state.selectedDay;

		var selectedDayBookings = this.state.availableBookings ? this.state.availableBookings[selectedDay] : [];

		var date = new Date();
		var time = parseInt(date.toLocaleString('en-GB', {hour: '2-digit', timeZone: 'Europe/Athens' }));
		var disabledStartTimes = [];
		selectedDayBookings.map((e, i) => {
			disabledStartTimes[i] = npeople > parseInt(e) || (selectedDay == 1 && i < time + 12)
		})

		if (disabledStartTimes[selectedStart] == true) selectedStart = disabledStartTimes.findIndex((a) => {return a == false});

		var disabledEndTimes = [];
		selectedDayBookings.map((e, i) => {
			var j = i+1;
			var disabled = false;
			if (selectedStart >= j) disabled = true;
			for (var k = selectedStart; k < j; k++)
			{
				if (npeople > parseInt(selectedDayBookings[k])) disabled = true;
			}
			disabledEndTimes[i] = disabled;
		});
		if (disabledEndTimes[selectedEnd-1] == true) selectedEnd = disabledEndTimes.findIndex((a) => {return a == false})+1;

		this.setState({
			selectedDayBookings : selectedDayBookings,
			disabledStartTimes : disabledStartTimes,
			disabledEndTimes : disabledEndTimes,
			selectedStart: selectedStart,
			selectedEnd: selectedEnd,
			selectedDay: selectedDay,
			npeople: npeople
		});
	}

	updatePeople = ( e ) => {
		this.updateMenus({npeople: e.target.value});
	};

	updateDay = (e) => {
		this.updateMenus({selectedDay: e.target.value});
	}

	updateStartTime = (e) => {
		this.updateMenus({selectedStart: e.target.value});
	}

	updateEndTime = (e) => {
		this.updateMenus({selectedEnd: e.target.value});
	}

	render() {

		return (
			<div class="modal fade" id="hireme" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" role="document">
			<div class="modal-content">
			<div class="background bg-45 rounded theme-header"><img src="img/background.png" alt="" /></div>
			<div class="modal-header">
			<h5 class="text-white">New booking</h5>
			<button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
			</button>
			</div>

			<div class="modal-body">
			<div class="row">
			<div class="col mt-3">

			<form onSubmit={this.submit} name="login">

			<div class="form-group">
			<label>Number of people:</label>
			<select class="form-control" id="people" name="people" value={this.state.npeople} onChange={this.updatePeople}>
			{Array.from(Array(5), (e, i) => {
				return <option value={i+1}>{i+1}</option>
			})}
			</select>
			</div>

			<div class="form-group">
			<label>Date:</label>
			<select class="form-control" value={this.state.bdate} onChange={this.updateDay}>
			{(this.state.availableBookings || []).map((e, i) => {
				var dateName = "";
				if (i == 0)
					dateName = "Today"; 
				else if (i == 1)
					dateName = "Tomorrow";
				else
					dateName = formatDate(new Date(Date.now() + 24 * 60 * 60 * 1000 * parseInt(i)));
				return <option value={i}>{dateName}</option>
			})}
			</select>
			</div>

			<div class="form-group">
			<label>Starting from:</label>
			<select class="form-control" value={this.state.selectedStart} onChange={this.updateStartTime} disabled={!this.state.disabledStartTimes.includes(false)}>

			{this.state.selectedDayBookings.map((e, i) => {
				return <option value={i} disabled={this.state.disabledStartTimes[i]}>{i}:00 ({e})</option>
			})}
			</select>
			</div>

			<div class="form-group">
			<label>Until:</label>
			<select class="form-control" value={this.state.selectedEnd} onChange={this.updateEndTime}>
			{
				this.state.selectedDayBookings.map((e, i) => {
					return <option value={i+1} disabled={this.state.disabledEndTimes[i]}>{i+1}:00</option>
				})
			}
			</select>

			</div>

			<div class="form-group">
			<label>Give Description</label>
			<textarea name="description" disabled="true" id="description" class="form-control" placeholder="Write Brief Description"></textarea>
			</div>

			<input class="btn btn-block btn-primary btn-lg rounded border-0 z-3" type="submit" value="Create booking" />
			</form>

			</div>
			</div>
			</div>

			</div>
			</div>
			</div>
		)
	};
}


class BookingsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			bookings: [],
			firstname: ""
		};
		bookings().then(this.recieveData);
		accountInfo().then(this.recieveAccountInfo);
	}

	recieveAccountInfo = (data) => {
		console.log(data);
		if (data["msg"]) {
		var info = data["msg"];
		this.setState({
			firstname: info["firstname"]
		});
		}
	}

	recieveData = (data) => {
		if (data) {
		var bookingsData = data["msg"];

		this.setState({
			bookings: (data["code"] == "FOUND") ? bookingsData : []
		});
		}
	}

	cancelBooking = (e) => {
		e.preventDefault();
		cancelBooking(parseInt(e.target.dataset.id));
		setTimeout(() => { bookings().then(this.recieveData) }, 500);
		//window.location.reload();
	}

	printBooking = (booking, filter) => {
		var oneHour = 1000 * 60 * 60;
		var bookingTime = Date.parse(booking['date']) + parseInt(booking['start'])*oneHour;
		var date = new Date();
		var time = date;// Date.parse(date.toLocaleString('en-GB', {timeZone: 'Europe/Athens' }));
		if (filter == undefined || (filter == "upcoming" && bookingTime > time) || (filter == "past" && bookingTime <= time)) {
		return (
			<li class="list-group-item">
			<div href="#" class="media shadow-15">
			<div class="media-body">
			<h5>{formatDate(new Date(bookingTime))}</h5>
			<p class="mb-0">{booking['start']}-{booking['end']} </p>
			<h2 class="title-number-carousel color-primary"><span class="text-primary">{booking['npeople']}</span><small> people</small></h2>
			</div>
			<div class="w-auto">
			{ (bookingTime > time) ?  <a href="#" onClick={this.cancelBooking} data-id={booking['id']} class="text-danger effort-time">Cancel </a>  : "" }
			</div>
			</div>
			</li>
		)}}

	render() {
		return (
			<div>
			<div class="col-12  mt-3 mb-4">
			<p class="text-uppercase font-weight-bold text-primary">Welcome,</p>
			<h1><span class="font-weight-light small">Good Morning</span><br /><b class="text-primary">{this.state.firstname}</b></h1>
			</div>
			<div class="row mx-0 mt-3">
			<div class="col">
			<a href="javascript:void(0)" class="btn btn-block btn-primary rounded border-0 " data-toggle="modal" data-target="#hireme">New booking</a>
			</div>
			</div>
			<h2 class="block-title">upcoming bookings</h2>
			{this.state.bookings ?
				<ul class="list-group media-list" id="bookings-list"> {
					this.state.bookings.map((booking) => this.printBooking(booking, "upcoming")) } </ul> : <div class="col">no bookings</div> }
			<h2 class="block-title">past bookings</h2>
			{this.state.bookings ?
				<ul class="list-group media-list" id="bookings-list"> {
					this.state.bookings.map((booking) => this.printBooking(booking, "past")) } </ul> : <div class="col">no bookings</div> }
			</div>
		) 
	}
}

export { BookingModal, BookingsPage }
